var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading || _vm.revenues.chartDatasets.length > 0
    ? _c(
        "div",
        [
          _c(
            "CCard",
            [
              _c(
                "CCardBody",
                [
                  _c("div", [
                    _c(
                      "h4",
                      {
                        staticClass: "card-title mb-0",
                        attrs: { id: "traffic" },
                      },
                      [_vm._v("Revenue By Year")]
                    ),
                    _vm.selectedRestaurant
                      ? _c("div", { staticClass: "small text-muted" }, [
                          _vm._v("For restaurant "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.selectedRestaurant.label)),
                          ]),
                          _vm._v(
                            " in " +
                              _vm._s(
                                _vm.selectedYears.slice().sort().join(", ")
                              )
                          ),
                        ])
                      : _c("div", { staticClass: "small text-muted" }, [
                          _vm._v(
                            "in " +
                              _vm._s(
                                _vm.selectedYears.slice().sort().join(", ")
                              )
                          ),
                        ]),
                  ]),
                  _c(
                    "CCard",
                    {
                      staticClass: "filters my-3",
                      attrs: { "accent-color": "warning" },
                    },
                    [
                      _c("CCardBody", { staticClass: "p-2" }, [
                        _c(
                          "div",
                          { staticClass: "grid" },
                          [
                            _c("v-select", {
                              staticClass: "v-select-filter",
                              attrs: {
                                options: _vm.years,
                                searchable: false,
                                multiple: "",
                              },
                              model: {
                                value: _vm.selectedYears,
                                callback: function ($$v) {
                                  _vm.selectedYears = $$v
                                },
                                expression: "selectedYears",
                              },
                            }),
                            _c("v-select", {
                              staticClass: "v-select-filter",
                              attrs: {
                                placeholder: "Select restaurant..",
                                reduce: (r) => r.value,
                                options: _vm.allRestaurants,
                              },
                              model: {
                                value: _vm.restaurant,
                                callback: function ($$v) {
                                  _vm.restaurant = $$v
                                },
                                expression: "restaurant",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.selectedYears.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-md-row flex-column flex-gap-3",
                        },
                        [
                          _c("div", { staticClass: "w-100" }, [
                            _c(
                              "div",
                              [
                                _c("h5", { staticClass: "chart-title" }, [
                                  _vm._v("Revenues"),
                                ]),
                                _c("ChartRevenue", {
                                  attrs: {
                                    revenues: _vm.revenues.chartDatasets,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.revenues.tblItems.length > 0
                                  ? _c("CDataTable", {
                                      staticClass: "table-sm",
                                      attrs: {
                                        striped: "",
                                        hover: "",
                                        items: _vm.revenues.tblItems,
                                        fields: _vm.revenues.tblFields,
                                        sorter: {
                                          external: false,
                                          resetable: true,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "month",
                                            fn: function ({ item }) {
                                              return [
                                                _c("td", [
                                                  _c("strong", [
                                                    _vm._v(_vm._s(item.month)),
                                                  ]),
                                                ]),
                                              ]
                                            },
                                          },
                                          _vm._l(
                                            _vm.revenues.data,
                                            function (r, i) {
                                              return {
                                                key: r.year,
                                                fn: function ({ item }) {
                                                  return _c(
                                                    "td",
                                                    {},
                                                    [
                                                      item[r.year]
                                                        ? _c(
                                                            "CLink",
                                                            {
                                                              on: {
                                                                click: () =>
                                                                  _vm.getRevenueDetails(
                                                                    null,
                                                                    r.year,
                                                                    item.m,
                                                                    _vm.restaurant
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "toCurrency"
                                                                    )(
                                                                      parseFloat(
                                                                        item[
                                                                          r.year
                                                                        ]
                                                                      ),
                                                                      "remove_thousand_separator"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "toCurrency"
                                                                  )(
                                                                    parseFloat(
                                                                      item[
                                                                        r.year
                                                                      ]
                                                                    ),
                                                                    "remove_thousand_separator"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  )
                                                },
                                              }
                                            }
                                          ),
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                                _c("mc-spinner", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.revenues.data.length == 0 &&
                                        _vm.selectedYears.length > 0,
                                      expression:
                                        "revenues.data.length == 0 && selectedYears.length > 0",
                                    },
                                  ],
                                  attrs: { opacity: 0.9 },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "w-100" }, [
                            _c(
                              "div",
                              [
                                _c("h5", { staticClass: "chart-title" }, [
                                  _vm._v("Order Count"),
                                ]),
                                _c("ChartOrder", {
                                  attrs: { orders: _vm.orders.chartDatasets },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.orders.tblItems.length > 0
                                  ? _c("CDataTable", {
                                      staticClass: "table-sm",
                                      attrs: {
                                        striped: "",
                                        hover: "",
                                        items: _vm.orders.tblItems,
                                        fields: _vm.orders.tblFields,
                                        sorter: {
                                          external: false,
                                          resetable: true,
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "month",
                                            fn: function ({ item }) {
                                              return [
                                                _c("td", [
                                                  _c("strong", [
                                                    _vm._v(_vm._s(item.month)),
                                                  ]),
                                                ]),
                                              ]
                                            },
                                          },
                                          _vm._l(
                                            _vm.orders.data,
                                            function (r, i) {
                                              return {
                                                key: r.year,
                                                fn: function ({ item }) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      item[r.year]
                                                        ? _c(
                                                            "CLink",
                                                            {
                                                              on: {
                                                                click: () =>
                                                                  _vm.getRevenueDetails(
                                                                    null,
                                                                    r.year,
                                                                    item.m,
                                                                    _vm.restaurant
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item[r.year]
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item[r.year]
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  )
                                                },
                                              }
                                            }
                                          ),
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                                _c("mc-spinner", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.orders.data.length == 0 &&
                                        _vm.selectedYears.length > 0,
                                      expression:
                                        "orders.data.length == 0 && selectedYears.length > 0",
                                    },
                                  ],
                                  attrs: { opacity: 0.9 },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "CAlert",
                            {
                              staticClass: "mt-2",
                              attrs: { show: "", color: "warning" },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { height: 24, name: "cil-warning" },
                              }),
                              _c("span", [_vm._v("Please select a year!")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CCardGroup",
            { staticClass: "card-columns cols-3", attrs: { columns: "" } },
            [
              _vm._l(_vm.reports.revenues.orderChannels, function (item) {
                return _c(
                  "CCard",
                  [
                    _c("CCardHeader", [
                      item.month
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.moment(item.month, "M").format("MMMM")
                              ) + " "
                            ),
                          ])
                        : _vm._e(),
                      _c("strong", [_vm._v(_vm._s(item.year))]),
                      _c("small", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(item.restaurant_name)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-header-actions" },
                        [
                          _c(
                            "CLink",
                            {
                              staticClass: "card-header-action btn-close",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.rejectRevenueDetails(
                                    item,
                                    "orderChannels"
                                  )
                                },
                              },
                            },
                            [_c("CIcon", { attrs: { name: "cil-x" } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "CCardBody",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { height: "2rem" },
                          },
                          [
                            _c("div", { staticClass: "w-100" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      parseFloat(
                                        item.revenues?.reduce(
                                          (acc, i) => acc + parseFloat(i.total),
                                          0
                                        )
                                      ),
                                      "remove_thousand_separator"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" Orders: "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    item.revenues?.reduce(
                                      (acc, i) => acc + parseFloat(i.cnt),
                                      0
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "w-100" },
                              [
                                item.weeks && !item.week
                                  ? _c("v-select", {
                                      attrs: {
                                        placeholder: "Select a week..",
                                        options: item.weeks,
                                        searchable: false,
                                      },
                                      on: {
                                        input: () => {
                                          _vm.getRevenueDetails(
                                            "orderChannels",
                                            item.year,
                                            item.month,
                                            item.restaurant,
                                            item.selectedWeek
                                          )
                                          item.selectedWeek = null
                                        },
                                      },
                                      model: {
                                        value: item.selectedWeek,
                                        callback: function ($$v) {
                                          _vm.$set(item, "selectedWeek", $$v)
                                        },
                                        expression: "item.selectedWeek",
                                      },
                                    })
                                  : _vm._e(),
                                item.week
                                  ? _c("strong", [
                                      _vm._v(
                                        " Week: " +
                                          _vm._s(
                                            _vm
                                              .moment(item.week.start)
                                              .format("DD/MM ddd")
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm
                                              .moment(item.week.end)
                                              .format("DD/MM ddd YYYY")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("ChartRevenueByOrderChannel", {
                          attrs: {
                            year: item.year,
                            month: item.month,
                            week: item.week,
                            restaurant: item.restaurant,
                            revenues: item.revenues,
                          },
                          on: {
                            "update:revenues": function ($event) {
                              return _vm.$set(item, "revenues", $event)
                            },
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column align-items-center",
                          },
                          [
                            _c("h5", { staticClass: "chart-title mt-2 mb-0" }, [
                              _vm._v("Order Channels"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "CFooter",
                      {
                        staticClass:
                          "justify-content-between footer-chart thin-scrollbar",
                        attrs: { fixed: false },
                      },
                      _vm._l(item.revenues, function (r) {
                        return _c("div", [
                          _c("strong", [_vm._v(_vm._s(r.channel) + ": ")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  parseFloat(r.total),
                                  "remove_thousand_separator"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "span",
                            { staticClass: "font-weight-light font-italic" },
                            [_vm._v(" (" + _vm._s(r.cnt) + ")")]
                          ),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.reports.revenues.paymentMethods, function (item) {
                return _c(
                  "CCard",
                  [
                    _c("CCardHeader", [
                      item.month
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.moment(item.month, "M").format("MMMM")
                              ) + " "
                            ),
                          ])
                        : _vm._e(),
                      _c("strong", [_vm._v(_vm._s(item.year))]),
                      _c("small", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(item.restaurant_name)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-header-actions" },
                        [
                          _c(
                            "CLink",
                            {
                              staticClass: "card-header-action btn-close",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.rejectRevenueDetails(
                                    item,
                                    "paymentMethods"
                                  )
                                },
                              },
                            },
                            [_c("CIcon", { attrs: { name: "cil-x" } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "CCardBody",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { height: "2rem" },
                          },
                          [
                            _c("div", { staticClass: "w-100" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      parseFloat(
                                        item.revenues?.reduce(
                                          (acc, i) => acc + parseFloat(i.total),
                                          0
                                        )
                                      ),
                                      "remove_thousand_separator"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" Orders: "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    item.revenues?.reduce(
                                      (acc, i) => acc + parseFloat(i.cnt),
                                      0
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "w-100" },
                              [
                                item.weeks && !item.week
                                  ? _c("v-select", {
                                      attrs: {
                                        placeholder: "Select a week..",
                                        options: item.weeks,
                                        searchable: false,
                                      },
                                      on: {
                                        input: () => {
                                          _vm.getRevenueDetails(
                                            "paymentMethods",
                                            item.year,
                                            item.month,
                                            item.restaurant,
                                            item.selectedWeek
                                          )
                                          item.selectedWeek = null
                                        },
                                      },
                                      model: {
                                        value: item.selectedWeek,
                                        callback: function ($$v) {
                                          _vm.$set(item, "selectedWeek", $$v)
                                        },
                                        expression: "item.selectedWeek",
                                      },
                                    })
                                  : _vm._e(),
                                item.week
                                  ? _c("strong", [
                                      _vm._v(
                                        " Week: " +
                                          _vm._s(
                                            _vm
                                              .moment(item.week.start)
                                              .format("DD/MM ddd")
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm
                                              .moment(item.week.end)
                                              .format("DD/MM ddd YYYY")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("ChartRevenueByPaymentMethod", {
                          attrs: {
                            year: item.year,
                            month: item.month,
                            week: item.week,
                            restaurant: item.restaurant,
                            revenues: item.revenues,
                          },
                          on: {
                            "update:revenues": function ($event) {
                              return _vm.$set(item, "revenues", $event)
                            },
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column align-items-center",
                          },
                          [
                            _c("h5", { staticClass: "chart-title mt-2 mb-0" }, [
                              _vm._v("Payment Methods"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "CFooter",
                      {
                        staticClass: "footer-chart thin-scrollbar",
                        attrs: { fixed: false },
                      },
                      _vm._l(item.revenues, function (r) {
                        return _c("div", { staticClass: "col-4 px-0" }, [
                          _c("strong", [_vm._v(_vm._s(r.pm) + ": ")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  parseFloat(r.total),
                                  "remove_thousand_separator"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "span",
                            { staticClass: "font-weight-light font-italic" },
                            [_vm._v(" (" + _vm._s(r.cnt) + ")")]
                          ),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.reports.revenues.referers, function (item) {
                return _c(
                  "CCard",
                  [
                    _c("CCardHeader", [
                      item.month
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.moment(item.month, "M").format("MMMM")
                              ) + " "
                            ),
                          ])
                        : _vm._e(),
                      _c("strong", [_vm._v(_vm._s(item.year))]),
                      _c("small", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(item.restaurant_name)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-header-actions" },
                        [
                          _c(
                            "CLink",
                            {
                              staticClass: "card-header-action btn-close",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.rejectRevenueDetails(
                                    item,
                                    "referers"
                                  )
                                },
                              },
                            },
                            [_c("CIcon", { attrs: { name: "cil-x" } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "CCardBody",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { height: "2rem" },
                          },
                          [
                            _c("div", { staticClass: "w-100" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      parseFloat(
                                        item.revenues?.reduce(
                                          (acc, i) => acc + parseFloat(i.total),
                                          0
                                        )
                                      ),
                                      "remove_thousand_separator"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" Orders: "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    item.revenues?.reduce(
                                      (acc, i) => acc + parseFloat(i.cnt),
                                      0
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "w-100" },
                              [
                                item.weeks && !item.week
                                  ? _c("v-select", {
                                      attrs: {
                                        placeholder: "Select a week..",
                                        options: item.weeks,
                                        searchable: false,
                                      },
                                      on: {
                                        input: () => {
                                          _vm.getRevenueDetails(
                                            "referers",
                                            item.year,
                                            item.month,
                                            item.restaurant,
                                            item.selectedWeek
                                          )
                                          item.selectedWeek = null
                                        },
                                      },
                                      model: {
                                        value: item.selectedWeek,
                                        callback: function ($$v) {
                                          _vm.$set(item, "selectedWeek", $$v)
                                        },
                                        expression: "item.selectedWeek",
                                      },
                                    })
                                  : _vm._e(),
                                item.week
                                  ? _c("strong", [
                                      _vm._v(
                                        " Week: " +
                                          _vm._s(
                                            _vm
                                              .moment(item.week.start)
                                              .format("DD/MM ddd")
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm
                                              .moment(item.week.end)
                                              .format("DD/MM ddd YYYY")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("ChartRevenueByReferer", {
                          attrs: {
                            year: item.year,
                            month: item.month,
                            week: item.week,
                            restaurant: item.restaurant,
                            revenues: item.revenues,
                          },
                          on: {
                            "update:revenues": function ($event) {
                              return _vm.$set(item, "revenues", $event)
                            },
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column align-items-center",
                          },
                          [
                            _c("h5", { staticClass: "chart-title mt-2 mb-0" }, [
                              _vm._v("Referers"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "CFooter",
                      {
                        staticClass: "footer-chart thin-scrollbar",
                        attrs: { fixed: false },
                      },
                      _vm._l(item.revenues, function (r) {
                        return _c("div", { staticClass: "col-6 px-0" }, [
                          _c("strong", [_vm._v(_vm._s(r.referer) + ": ")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  parseFloat(r.total),
                                  "remove_thousand_separator"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "span",
                            { staticClass: "font-weight-light font-italic" },
                            [_vm._v(" (" + _vm._s(r.cnt) + ")")]
                          ),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      )
    : _c("CSpinner", { attrs: { color: "primary" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }